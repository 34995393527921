import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Example from "../components/SalePanel";
import HomePage from "../pages/HomePage";
import Marketplace from "../pages/Marketplace";
import Login from "../pages/Login";
import MyList from "../pages/MyList";
import EthicsPage from "../pages/EthicsPage";
import TermsPage from "../pages/TermsPage";
import PressPage from "../pages/PressPage";
import PrivacyPage from "../pages/PrivacyPage";
import ProductList from "../pages/ProductList";
import PropertiesList from "../pages/PropertiesList";
import { Wallet } from "../pages/wallets";
import { getCategories, getProductNames } from "../Redux/options/slice";
import { getProductsList } from "../Redux/products/slice";
import { ProtectedRoute } from "./ProtectedRoute";
import TransactionExplorer from "../components/TransactionExplorer";
import MyGPT from "../pages/VertoGPT";
import Footer from "../components/Footer";
import SecondFooter from "../components/SecondFooter";

const MainContent = () => {
  return (
    <div className="d-flex flex-column sticky-footer" style={{ overflow: "hidden" }}>
      <Routes>
        <Route
          index
          element={
            // <ProtectedRoute>
            <HomePage />
            // </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          // path="/home/:level"
          path="/marketplace"
          element={
            // <ProtectedRoute>
            <Marketplace />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/my-list"
          element={
            <ProtectedRoute>
              <MyList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wallet/:userId/:walletId"
          element={
            <ProtectedRoute>
              <Wallet />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wallet"
          element={
            <ProtectedRoute>
              <Wallet />
            </ProtectedRoute>
          }
        />
        <Route
          // path="/home/:level"
          path="/gpt"
          element={
            <MyGPT />
          }
        />
        <Route
          path="/transaction-explorer"
          element={<TransactionExplorer />}
        />
        <Route
          path="/ethics"
          element={<EthicsPage />}
        />
        <Route
          path="/terms"
          element={<TermsPage />}
        />
        <Route
          path="/privacy"
          element={<PrivacyPage />}
        />
        <Route
          path="/press"
          element={<PressPage />}
        />
      </Routes>
    </div>
  );
};

const WebRouter = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
    dispatch(getProductsList());
    // dispatch(getProductNames())
  }, []);

  return (
    <BrowserRouter>
      <MainContent />
    </BrowserRouter>
  );
};

export default WebRouter;
