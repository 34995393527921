import { CircularProgress, FormControl, Grid, Input, InputLabel, MenuItem, NativeSelect, OutlinedInput, Stack, Select, TextareaAutosize, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import { BASE_URL } from '../Redux/apiConfig';
import ImageCropper from "./ImageCropper";
import FileInput from "./FileInput";
import { FileButton, Button as MButton, Autocomplete, TextInput, Textarea, Select as MSelect, Title, Loader } from '@mantine/core';

import { IntlProvider, FormattedMessage, useIntl } from "react-intl";
import { getLocationsList, getProductStateList, getProductsList, getUOMList, setUOMList } from "../Redux/products/slice";
import { getCategories } from "../Redux/options/slice";

function CreatePropertiesModal({ toggle, modal, selectedData, onSave, onDraftSave }) {
  const dispatch = useDispatch();
  const userinfo = JSON.parse(localStorage.getItem('userinfo'));
  const { productStateList, productsList, UOMList } = useSelector((state) => state.products);
  // const { productsList } = useSelector(state => ({ productsList: state.products.productsList }))
  // const { productStateList } = products.productStateList
  // const locationsList = useSelector((state) => state.products.locationsList);
  let { locationsList } = useSelector(
    (state) => state.products
  );

  locationsList = locationsList
    .slice()
    .sort((a, b) => (a.location && b.location ? a.location.localeCompare(b.location) : 0));

  const [image, setImage] = useState("");
  const [currentPage, setCurrentPage] = useState("choose-img");
  const [imgAfterCrop, setImgAfterCrop] = useState("");

  // Invoked when new image file is selected
  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
    setCurrentPage("crop-img");
  };

  // Generating Cropped Image When Done Button Clicked
  const onCropDone = (imgCroppedArea) => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = imgCroppedArea.width;///2;
    canvasEle.height = imgCroppedArea.height;///2;

    const context = canvasEle.getContext("2d");

    let imageObj1 = new Image();
    imageObj1.src = image;
    imageObj1.onload = function () {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height
      );

      const dataURL = canvasEle.toDataURL("image/jpeg");

      setImgAfterCrop(dataURL);
      setCurrentPage("img-cropped");
    };
  };

  // Handle Cancel Button Click
  const onCropCancel = () => {
    setCurrentPage("choose-img");

  };

  useEffect(() => {

    if (!modal)
      clearData();

  }, [modal]);

  const sortedProductList = Array.from(productsList)
    .filter(item => typeof item.label === 'string') // Filter out objects without a valid label property
    .sort((a, b) => a.label.localeCompare(b.label));

  const { categories } = useSelector((state) => state.options)

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [price, setPrice] = useState("");
  const [city, setCity] = useState("");
  const [physicalState, setPhysicalState] = useState("");
  const [buildYear, setBuildYear] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [quantity, setQuantity] = useState(null);
  const [fromPeriod, setFromPeriod] = useState(new Date().toISOString().split('T')[0]);
  const [toPeriod, setToPeriod] = useState(new Date().toISOString().split('T')[0]);
  const [len, setLen] = useState(0);
  const CHAR = 160;
  const [savingData, setSavingData] = useState(false);
  const [savingDraftData, setSavingDraftData] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [selectedUOM, setSelectedUOM] = useState("");

  const saveData = () => {
    setSavingData(true);
    // Assuming title and physicalState are selected values
    const selectedProductState = physicalState;

    // Assuming locationsList is a dictionary with id and label
    const selectedLocation = locationsList.find(location => location.location === city);
    const productName = sortedProductList.find(product => product.id_category === title)?.label;
    const productRefId = productStateList.find(state => state.product_state === selectedProductState && state.id_category === title && state.product_state !== null)?.product_ref_id || null;
    const locationsIds = selectedLocation?.id || null;
    const UOMId = UOMList.find(state => state.UOM === selectedUOM)?.UOMId || null; 
    const quantityNum = Number(quantity);

    var obj = {
      productName: productName,
      productNotes: description,
      property_img: imgAfterCrop,
      physicalState: physicalState,
      period: `${fromPeriod} - ${toPeriod}`,
      quantity,
      location: city,
      ownerAccountId: userinfo.accountId,
      OwnerId: userinfo.userId,
      ProductId: productRefId,
      Quantity: quantityNum,
      UOMId: UOMId,
      QuantityUOM: `${quantity} ${selectedUOM}`,
      RegionId: locationsIds,
      Period: `${fromPeriod} - ${toPeriod}`,
      PublishId: 0,
      ProductLotDetail: description,
    };

    onSave(obj, toggle);
    setSavingData(false);
  };

  const saveDraftData = () => {
    setSavingDraftData(true);
    // Assuming title and physicalState are selected values
    const selectedProductState = physicalState;

    // Assuming locationsList is a dictionary with id and label
    const selectedLocation = locationsList.find(location => location.location === city);
    const productName = sortedProductList.find(product => product.id_category === title)?.label;
    const productRefId = productStateList.find(state => state.product_state === selectedProductState && state.id_category === title && state.product_state !== null)?.product_ref_id || null;
    const locationsIds = selectedLocation?.id || null;
    const UOMId = UOMList.find(state => state.UOM === selectedUOM)?.UOMId || null; 
    const quantityNum = Number(quantity);

    var draftObj = {
      productName: productName,
      productNotes: description,
      property_img: imgAfterCrop,
      physicalState: physicalState,
      period: `${fromPeriod} - ${toPeriod}`,
      quantity,
      location: city,
      ownerAccountId: userinfo.accountId,
      OwnerId: userinfo.userId,
      ProductId: productRefId,
      Quantity: quantityNum,
      UOMId: UOMId,
      RegionId: locationsIds,
      Period: `${fromPeriod} - ${toPeriod}`,
      PublishId: 0,
      ProductLotDetail: description,
      // productLotUuid: selectedData.productID,
    };

    onDraftSave(draftObj, toggle); // Call the onDraftSave method
    setSavingDraftData(false);
  };

  const clearData = () => {
    setTitle("");
    setDescription("");
    setCategory("");
    setBuildYear("");
    setCity("");
    setPrice("");
    setQuantity("");
    setFromPeriod("");
    setToPeriod("");
    setPhysicalState("");
    setImage("");
    setImgAfterCrop("");
    setCurrentPage("choose-img");
    setUOMList("");
  };

  function imageUploaded(file) {
    // var file = document.getElementById("fileType").files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = function () {
        const base64String = reader.result;
        setImageFile({ name: file.name, data: base64String });
      };

      reader.readAsDataURL(file);
    }
  }

  useEffect(() => {
    // console.log(description.length, len);
    setLen(CHAR - description.length);
  }, [description]);


  const intl = useIntl();

  useEffect(() => {
    if (modal) {
      dispatch(getLocationsList());
      dispatch(getProductStateList());
      dispatch(getProductsList());
      dispatch(getCategories());
      dispatch(getUOMList());
    }
  }, [modal, dispatch]); // This only runs to trigger dispatches when modal opens or closes
  
  useEffect(() => {
    // Check if selectedData is valid and update state accordingly
    if (selectedData) {
      setTitle(selectedData.productName || "");
      setDescription(selectedData.productNotes || "");
      setPhysicalState(selectedData.physicalState || "");
      setCity(selectedData.location || "");
      setCategory(selectedData.categoryId || ""); // Assuming category ID is stored directly
      setPrice(selectedData.price || "");
      setQuantity(selectedData.quantity || "");
      setFromPeriod(selectedData.fromPeriod || new Date().toISOString().split('T')[0]);
      setToPeriod(selectedData.toPeriod || new Date().toISOString().split('T')[0]);
      setSelectedUOM(selectedData.UOMId || ""); // Assuming UOMId is stored directly
      setImageFile(selectedData.property_img || ""); // Assuming this is how image data is stored
    }
  }, [selectedData]); // This handles updates when selectedData changes  

  // Calculate the smallest product IDs for each category
  const calculateSmallestProductIds = () => {
    const smallestProductIds = {};
    sortedProductList.forEach(product => {
      if (!smallestProductIds[product.id_category] || product.id < smallestProductIds[product.id_category].id) {
        smallestProductIds[product.id_category] = { id: product.id, label: product.label };
      }
    });
    return smallestProductIds;
  };

  // Get the smallest product IDs
  const smallestProductIds = calculateSmallestProductIds();

  // Map over the smallest product IDs and create an array of <option> elements
  const smallestProductOptions = Object.entries(smallestProductIds).map(([id_category, { id, label }]) => (
    <option key={id} value={id}>{label}</option>
  ));

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} style={{ height: "auto" }}>
        <ModalHeader toggle={toggle}>{<FormattedMessage id="modal.createprod.add" />}</ModalHeader>
        <ModalBody>
          <Grid container spacing={1}>
            <Grid item>
              <FormControl sx={{ m: 0, minWidth: 120 }}>
                <InputLabel htmlFor="demo-dialog-native"><FormattedMessage id="product.name" /></InputLabel>
                <Select
                  native
                  value={title}
                  onChange={(e) => setTitle(parseInt(e.target.value))}
                  input={<OutlinedInput label="Product Name" id="demo-dialog-native" />}
                >
                <option aria-label="None" value="" />
                  {smallestProductOptions}
              </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextareaAutosize
                aria-label="minimum height"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                label= {intl.formatMessage({ id:"product.description" })}
                minRows={3}
                placeholder={intl.formatMessage({ id:"product.description" })}
                style={{ width: "100%", marginTop: "5px", padding: "15px" }}
                maxLength={CHAR}
              />
              <Typography variant="subtitle2"><FormattedMessage id="userInput.msg.charaleft" /> {len}</Typography>
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  id="outlined-basic"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  label={intl.formatMessage({ id:"product.quantity" })}
                  variant="outlined"
                  fullWidth
                  type="number" // only allow numeric input
                  InputProps={{
                    inputProps: {
                      min: 0 // set minimum value to 0
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl sx={{ m: 0, minWidth: 120 }}>
                  <InputLabel htmlFor="uom-select"><FormattedMessage id="product.uom"></FormattedMessage></InputLabel>
                  <Select
                    value={selectedUOM}
                    onChange={(e) => setSelectedUOM(e.target.value)}
                    inputProps={{ id: "uom-select" }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 100,
                          overflow: 'auto',
                          maxWidth: 100 
                        }
                      }
                    }}
                  >
                    {UOMList.map((uom) => (
                      <MenuItem key={uom.UOMId} value={uom.UOM}>
                        {uom.UOM}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>
              <FormControl sx={{ m: 0, minWidth: 120 }}>
                <InputLabel htmlFor="demo-dialog-native"><FormattedMessage id="product.physicalstate" /></InputLabel>
                <Select
                  native
                  value={physicalState}
                  onChange={(e) => setPhysicalState(e.target.value)}
                  input={<OutlinedInput label={intl.formatMessage({ id:"product.physicalstate" })} id="demo-dialog-native" />}>
                  <option aria-label="None" value="" />
                  {
                    productStateList
                      .filter(product => product.product_ref_id === title && product.product_state !== null) // Filtering condition added
                      .sort((a, b) => {
                        // Add null checks to prevent errors
                        const stateA = a.product_state || "";
                        const stateB = b.product_state || "";
                        return stateA.localeCompare(stateB);
                      })
                      .map((product, index) => (
                        <option key={product.mps_id} value={product.product_state}>{index}: {product.product_state}</option>
                      ))
                  }
                  {/* {productStateList
                      .filter(state => product.product_ref_id === title)
                      .map((state, index) => (
                        <option
                          key={state.ActionVerb}
                          value={state.ActionVerb}
                          disabled={state.product_ref_id <= selectedData.product_ref_id}
                          style={{
                            color: state.product_ref_id > selectedData.product_ref_id ? 'inherit' : '#ccc', // Lighter font color for disabled options
                            fontStyle: state.product_ref_id > selectedData.product_ref_id ? 'normal' : 'italic', // Italics for disabled options
                          }}
                        >
                          {index + 1}: {state.ActionVerb}
                        </option>
                      ))} */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Autocomplete
                label={intl.formatMessage({ id: "product.location" })}
                placeholder="Select a location"
                data={locationsList.map(location => location.location)}
                value={city}
                onChange={setCity}
                maxDropdownHeight={200} // Limit the dropdown height to 200px
                sx={{
                  '.mantine-Autocomplete-dropdown': {
                    maxHeight: '200px',
                  },
                }}
              />
            </Grid>
            <Grid item>
              <div><FormattedMessage id="product.period" /></div>
              <TextField
                id="outlined-basic"
                type={"date"}
                sx={{ width: "50%" }}
                value={fromPeriod}
                placeholder={""}
                onChange={(e) => setFromPeriod(e.target.value)}
                variant="outlined"
              />
              <TextField
                id="outlined-basic"
                type={"date"}
                sx={{ width: "50%" }}
                value={toPeriod}
                placeholder={""}
                onChange={(e) => setToPeriod(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item>
            </Grid>
            <Grid item>
              <FileButton style={{ backgroundColor: 'grey', color: 'white' }} onChange={(file) => imageUploaded(file)} accept="image/*">
                {(props) => <MButton {...props}><FormattedMessage id="button.chooseimg"></FormattedMessage> </MButton>}
              </FileButton>
                {imageFile && (
                  <div style={{ marginTop: '10px' }}>
                    {imageFile.name}
                  </div>
                )}
            </Grid>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <MButton color="primary" onClick={() => saveDraftData() }>
            {savingDraftData ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : (
              <FormattedMessage id="button.savedraft" />
            )}
          </MButton>{" "}
          <MButton color="primary" onClick={() => saveData()}>
            {savingData ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : (
              <FormattedMessage id="button.save" />
            )}
          </MButton>
          <MButton
            color="secondary"
            onClick={() => {
              toggle();
            }}
          >
            <FormattedMessage id="button.cancel" />
          </MButton>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default CreatePropertiesModal;