import { IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import { HashConnect } from "hashconnect";
import { MenuConfig } from "../configs/MenuConfig";
import { useState } from "react";
import brandLogo from "./../assets/brand.png";
import { useDispatch, useSelector } from "react-redux";
import { logout, setRedirectUrl, setShowLogin } from "../Redux/user/slice";
import LogoutIcon from '@mui/icons-material/Logout';
import {
  createStyles,
  Header,
  Group,
  Button,
  UnstyledButton,
  Text,
  ThemeIcon,
  Box,
  Burger,
  rem, TextInput, Input, Textarea, Modal
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { signUpForm } from "../Redux/properties/PropertiesSlice";
import { setLanguage } from "../Redux/languageSlice";

const useStyles = createStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    height: '60%',
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.md,
    margin: '0 5px', // Space between buttons

    [theme.fn.smallerThan('xs')]: {
      height: rem(42),
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      margin: '0', // Avoid extra space in mobile view
    },

    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    }),

    '&:active': theme.activeStyles,
    
  },

  subLink: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    }),

    '&:active': theme.activeStyles,
  },

  hiddenMobile: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },
}));

const MenuPanel = ({ level }) => {
  const dispatch = useDispatch();
  const { redirectUrl, isUserLoggedIn, showLogin } = useSelector((state) => state.user);
  const selectedLanguage = useSelector((state) => state.language);

  const [openQr, setOpenQR] = useState(false);
  const [pairingString, setPairingString] = useState(false);
  const { userInfo } = useSelector((state) => state.user);
  const userinfo = JSON.parse(localStorage.getItem('userinfo'));  
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const { classes, theme } = useStyles();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // send to backend api
    dispatch(signUpForm(formData));
    // Reset the form
    setFormData({
      name: '',
      email: '',
      message: '',
    });
    // Close the modal
    setShowModal(false);
  };

  const handleSignUpClick = () => {
    setShowModal(true);
  };

  const handleLogout = () => {

    // Retrieve and set the selected language from local storage
    dispatch(logout());

    localStorage.clear('userLogged');
    localStorage.clear('userinfo');

    dispatch(setLanguage(selectedLanguage));

    if (window.location.pathname === '/my-list') {
      window.location.href = '/';
    } 
    // else {
    //   window.location.reload();   //after logout language switches to English, we reload to redraw interface with English
    // }
  };

  const handleLoginClick = () => {
    dispatch(setShowLogin({ value: true, cb: null }));
  };

  const handleLanguageChange = (language) => {
    const oldLanguage = JSON.parse(localStorage.getItem('language')) ;
    dispatch(setLanguage(language)); // Dispatch the setLanguage action to update the selected language
    localStorage.setItem('language', JSON.stringify(language));
  };

  const languageOptions = [
    { value: "en", label: <FormattedMessage id="languageOptions.en" /> },
    { value: "fr", label: <FormattedMessage id="languageOptions.fr" /> },
    { value: "es", label: <FormattedMessage id="languageOptions.es" /> },
    { value: "pt", label: <FormattedMessage id="languageOptions.pt" /> },
  ];

  const intl = useIntl();

  useEffect(() => {
    if (redirectUrl && isUserLoggedIn && !showLogin) {
      window.location.replace(redirectUrl);            // Redirect the user to the specified URL after setting the user info
      dispatch(setRedirectUrl({ redirectUrl: "" }));
    }
  }, [isUserLoggedIn, redirectUrl, showLogin]);


  useEffect(() => {
    if (!redirectUrl && isUserLoggedIn && !showLogin) {
      window.location.reload();
    }
  }, [isUserLoggedIn, showLogin]);

  return (
    <>
      <Box pb={10}>
        <Header height={70}>
          <div style={{ display: "flex"}}>
            <img
              src={brandLogo}
              alt="Brand Logo"
              style={{ width: "10%", height: "100%" }}
            />
            <Group spacing={0} className={classes.hiddenMobile}>
              {MenuConfig
                .filter((x) => level === "3" || x.name !== "User")
                .map((item, index) => (
                  <MenuItem key={index} Value={item} className={classes.link} selectedLanguage={selectedLanguage} />
                ))
              }
            </Group>
            <Group position="right" >
              {userInfo?.userId ? (
                <>
                  <Typography className={classes.link} sx={{ fontSize: 'smaller', backgroundColor: '#FFA500', color: 'white', padding: '8px', borderRadius: '4px'  }}>
                    <FormattedMessage id="userInfo.acctID" /> {userInfo.accountId}
                    <br />
                    <FormattedMessage id="userInfo.userID" /> {userInfo.userId}
                  </Typography>
                  <Button onClick={handleLogout} sx={{ color: 'black' }}>
                    <LogoutIcon /> <FormattedMessage id="button.logout" />
                  </Button>
                </>
              ) : (
                <>
                  <Button variant="default" size="small" className={classes.link} onClick={handleLoginClick}>
                    <FormattedMessage id="button.login" />
                  </Button>
                  <Button onClick={handleSignUpClick} size="small" className={classes.link}>
                    <FormattedMessage id="button.signup" />
                  </Button>
                  <Modal
                    opened={showModal}
                    onClose={() => setShowModal(false)}
                    title= {<FormattedMessage id="signupmodal.title" />}
                    size="lg"
                    style={{ overflow: 'hidden' }} // Hide the modal's scrollbar
                  >
                    <div style={{
                      padding: '16px',
                      maxHeight: '400px', // Set a maximum height for the content
                      overflowY: 'auto', // Make the content scrollable
                    }}>
                      <form onSubmit={handleSubmit}>
                        <Text  style={{ padding: '4px' }}><FormattedMessage id="signupmodal.instruction" /></Text>
                        <Input
                          required
                          value={formData.name}
                          onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                          }
                          label= {<FormattedMessage id="userInput.name" />}
                          placeholder= {intl.formatMessage({ id:"userInput.name.placeholder" })}
                          style={{ padding: '16px' }}
                        />
                        <Input
                          required
                          value={formData.email}
                          onChange={(e) =>
                            setFormData({ ...formData, email: e.target.value })
                          }
                          type="email"
                          label={<FormattedMessage id="userInput.email" />}
                          placeholder= {intl.formatMessage({id:"userInput.email.placeholder" })}
                          style={{ padding: '16px' }}
                        />
                        <Input
                          required
                          value={formData.message}
                          onChange={(e) =>
                            setFormData({ ...formData, message: e.target.value })
                          }
                          multiline
                          rows={4}
                          label={< FormattedMessage id="userInput.msg" />}
                          placeholder={intl.formatMessage ({id:"userInput.msg.placeholder" })}
                          style={{ padding: '16px' }}
                        />
                        <Button style={{ margin: '0px' }} type="submit">
                          <FormattedMessage id="button.submit" />
                        </Button>
                      </form>
                    </div>
                  </Modal>
                </>
              )}
              <select
                value={selectedLanguage}
                onChange={(e) => handleLanguageChange(e.target.value)}
                style={{ marginRight: "25px", fontSize: "12px" }} // Adjust the margin to create space between the dropdown and the links
              >
                {languageOptions.map((language) => (
                  <option key={language.value} value={language.value}>
                    {language.label}
                  </option>
                ))}
              </select>
            </Group>
          </div>
        </Header>
      </Box>
    </>
  );
};

const MenuItem = ({ Value, className, selectedLanguage }) => {
  const dispatch = useDispatch()
  const isUserLogged = localStorage.getItem('userLogged');
  const activate = ({ url, enableAuthentication, newPage }) => {
    const isMyListPage = url === '/my-list';
    const getUser = localStorage.getItem("userLogged");

    if (isMyListPage && !isUserLogged) {
      dispatch(setRedirectUrl({ redirectUrl: url }));
      dispatch(setShowLogin({ value: true, cb: null }));
    } else if (newPage) {
      window.open(url, '_blank')
    } else {
      window.location.replace(url);
    }
  };

  // Get the current location using useLocation hook from react-router-dom
  const location = useLocation();

  // Check if the current location matches the item's URL
  const isActive = location.pathname === Value.url;

  return (
    <Link
      className={className}
      onClick={() => activate(Value)}
      style={{
        textDecoration: 'none',
        color: isActive ? '#fff' : '#000', // Apply different colors based on active state
        fontWeight: 500,
        fontSize: '14px',
        padding: '8px 8px',
        backgroundColor: isActive ? '#009688' : 'transparent', // Apply different background color based on active state
        borderRadius: '4px',
      }}
    >
      <FormattedMessage id={`menu.${Value.name}`} />
    </Link>
  );
};


export default MenuPanel;
