import React, { useLayoutEffect,useEffect, useState } from 'react';
import MenuPanel from "../components/MenuPanel";
import SecondFooter from '../components/SecondFooter'; // Import the SecondFooter component
import Footer from '../components/Footer'; // Import the Footer component
import MobileMenuPanel from "../components/MobileMenuPanel";
import MobileSecondFooter from '../components/MobileSecondFooter'; // Import the SecondFooter component
import MobileFooter from '../components/MobileFooter'; // Import the Footer component
import { LoginModal } from '../components/LoginModal';

const Chatbot = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdn.botpress.cloud/webchat/v1/inject.js'
    script.async = true
    document.body.appendChild(script)
 
    script.onload = () => {
      window.botpressWebChat.init({
        botId: 'f90b5c7f-3c17-4ae4-b195-6aa6767fc945',
        hostUrl: 'https://cdn.botpress.cloud/webchat/v1',
        messagingUrl: 'https://messaging.botpress.cloud',
        clientId: '<clientID>',
      })
    }
  }, [])
 
  return <div id="webchat" />
}

const MyGPT = () => {
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const detectMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    detectMobile();

    window.addEventListener('resize', detectMobile);

    return () => {
      window.removeEventListener('resize', detectMobile);
    };
  }, []);

  return (
    <>
      {/* Responsive view based on device type */}
      {isMobile ? (
        <div>
        <MobileMenuPanel />
        <div style={{ textAlign: 'center', fontFamily: 'Montserrat', maxWidth: '1000px', margin: '0 auto', padding: '20px' }}>
        <h1 style={{ margin: '20px' }}>Chatbot specialist in EU Deforestation Regulation</h1>
          <p style={{ margin: '20px', fontSize: 20 }}>Try the following questions and ask how Verto can help you prepare to new regulation:</p>
          <ul style={{ textAlign: 'left', paddingLeft: '20px', marginBottom: '20px', fontSize: 18 }}>
            <li>Can you explain me the EUDR in simple terms?</li>
            <li>Which products are concerned by this regulation?</li>
            <li>Is there any Framework to satisfy the regulation?</li>
            <li>How Verto can help answering the EUDR?</li>
            <li>What is the advantage of Verto to satisfy the EUDR?</li>
          </ul>
          <div style={{ margin: '0 auto', width: '100%' }}>
          <iframe
              style={{ border: 'none',  margin: '0 auto', width: '100%'  }}
              srcdoc="<body><script src='https://cdn.botpress.cloud/webchat/v1/inject.js'></script>
              <script>
                window.botpressWebChat.init({
                  'composerPlaceholder': 'Chat with EUDR specialist bot',
                  'botConversationDescription': 'This chatbot was built by Verto',
                  'botId': 'f90b5c7f-3c17-4ae4-b195-6aa6767fc945',
                  'hostUrl': 'https://cdn.botpress.cloud/webchat/v1',
                  'messagingUrl': 'https://messaging.botpress.cloud',
                  'clientId': 'f90b5c7f-3c17-4ae4-b195-6aa6767fc945',
                  'webhookId': '12e19aa2-d849-4727-a68e-06613c703e00',
                  'lazySocket': true,
                  'themeName': 'prism',
                  'frontendVersion': 'v1',
                  'showPoweredBy': true,
                  'theme': 'prism',
                  'themeColor': '#2563eb'            
                });
              window.botpressWebChat.onEvent(function () { window.botpressWebChat.sendEvent({ type: 'show' }) }, ['LIFECYCLE.LOADED']);
              </script></body>"
              width="100%"
              height="500px"
            ></iframe>
          </div>
        </div>
        <MobileSecondFooter />
        <MobileFooter />
        <LoginModal/>
        </div>
      ) : (
        <>
        <MenuPanel />
        <div style={{ textAlign: 'center', fontFamily: 'Montserrat', maxWidth: '1000px', margin: '0 auto', padding: '20px' }}>
        <h1 style={{ margin: '20px' }}>Chatbot specialist in EU Deforestation Regulation</h1>
          <p style={{ margin: '20px', fontSize: 20 }}>Try the following questions and ask how Verto can help you prepare to new regulation:</p>
          <ul style={{ textAlign: 'left', paddingLeft: '20px', marginBottom: '20px', fontSize: 18 }}>
            <li>Can you explain me the EUDR in simple terms?</li>
            <li>Which products are concerned by this regulation?</li>
            <li>Is there any Framework to satisfy the regulation?</li>
            <li>How Verto can help answering the EUDR?</li>
            <li>What is the advantage of Verto to satisfy the EUDR?</li>
          </ul>
          <div style={{ margin: 'auto', width: '50%' }}>
          <iframe
              style={{ border: 'none', width: '100%'  }}
              srcdoc="<body><script src='https://cdn.botpress.cloud/webchat/v1/inject.js'></script>
              <script>
                window.botpressWebChat.init({
                  'composerPlaceholder': 'Chat with EUDR specialist bot',
                  'botConversationDescription': 'This chatbot was built by Verto',
                  'botId': 'f90b5c7f-3c17-4ae4-b195-6aa6767fc945',
                  'hostUrl': 'https://cdn.botpress.cloud/webchat/v1',
                  'messagingUrl': 'https://messaging.botpress.cloud',
                  'clientId': 'f90b5c7f-3c17-4ae4-b195-6aa6767fc945',
                  'webhookId': '12e19aa2-d849-4727-a68e-06613c703e00',
                  'lazySocket': true,
                  'themeName': 'prism',
                  'frontendVersion': 'v1',
                  'showPoweredBy': true,
                  'theme': 'prism',
                  'themeColor': '#2563eb'          
                });
              window.botpressWebChat.onEvent(function () { window.botpressWebChat.sendEvent({ type: 'show' }) }, ['LIFECYCLE.LOADED']);
              </script></body>"
              width="500px"
              height="600px"
            ></iframe>
          </div>
        </div>
        <SecondFooter />
        <Footer />
        <LoginModal/>
        </>
      )}
    </>
  );
};

export default MyGPT;