import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { Menu as MenuIcon, Close as CloseIcon, Lock as LockIcon, PersonAdd as PersonAddIcon, ExitToApp as LogoutIcon } from "@mui/icons-material";
import {
  createStyles,
  Header,
  Group,
  Button,
  UnstyledButton,
  Text,
  ThemeIcon,
  Box,
  Burger,
  rem, TextInput, Input, Textarea , Modal
} from '@mantine/core';
import { logout, setShowLogin, setRedirectUrl } from "../Redux/user/slice";
import brandLogo from "./../assets/brand.png";
import { MenuConfig } from "../configs/MenuConfig";
import { FormattedMessage } from "react-intl";
import { signUpForm } from "../Redux/properties/PropertiesSlice";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MobileMenuIcon = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const MobileMenuHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(3),
}));

const MobileMenuList = styled(List)({
  width: "20%",
  paddingLeft: 0, // Remove default padding
  justifyContent: "flex-start", // Align to the left
});

const MobileMenuItem = styled(ListItem)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start", // Align to the left
  alignItems: "left",
  marginBottom: theme.spacing(1),
}));

const MobileMenuPanel = ({ selectedLanguage }) => {
  const dispatch = useDispatch();
  const { redirectUrl, isUserLoggedIn, showLogin } = useSelector((state) => state.user);
  const { userInfo } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  useEffect(() => {
    if (redirectUrl && isUserLoggedIn && !showLogin) {
      window.location.replace(redirectUrl);            // Redirect the user to the specified URL after setting the user info
      dispatch(setRedirectUrl({ redirectUrl: "" }));
    }
  }, [isUserLoggedIn, redirectUrl, showLogin]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // send to backend api
    dispatch(signUpForm(formData));
    // Reset the form
    setFormData({
      name: '',
      email: '',
      message: '',
    });
    // Close the modal
    setShowModal(false);
  };

  const handleSignup = () => {
    setOpen(false);
    setShowModal(true);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.clear("userLogged");
    localStorage.clear("userinfo");
    setOpen(false);
    if (window.location.pathname === "/my-list") {
      window.location.href = "/";
    }
  };

  const handleLoginClick = () => {
    dispatch(setShowLogin({ value: true, cb: handleSuccessfulLogin }));
    setOpen(false);
  };

  const handleSuccessfulLogin = () => {
    // Reload the page
    window.location.reload();
  };

  const isUserLogged = localStorage.getItem('userLogged');
  //TODO isUserLoggedIn lost its value by unknown reasons after validateUserAPI execution

  const activate = ({ url, enableAuthentication, newPage }) => {
    const isMyListPage = url === '/my-list';

    if (isMyListPage && !isUserLogged) {
      dispatch(setRedirectUrl({ redirectUrl: url }));
      dispatch(setShowLogin({ value: true, cb: null }));
      setOpen(false);
    } else if (newPage) {
      window.open(url, '_blank');
    } else {
      window.location.replace(url);
    }
  };

  return (
    <>
      <MobileMenuHeader>
        <MobileMenuIcon onClick={handleDrawerToggle}>
          {open ? <CloseIcon /> : <MenuIcon />}
        </MobileMenuIcon>
        <img src={brandLogo} alt="Brand Logo" style={{ width: "30%", height: "auto" }} />
      </MobileMenuHeader>
      <Drawer anchor="left" open={open} onClose={handleDrawerToggle} sx={{
        width: '80%', // Set the width to 80%
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '80%',
        },
      }}>
        <MobileMenuList>
          {MenuConfig.filter((x) => x.name !== "User").map((item, index) => (
            <MobileMenuItem key={index} onClick={() => activate(item)} sx={{
              whiteSpace: 'nowrap',
              width: '100%',
            }}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={<FormattedMessage id={`menu.${item.name}`}/>} />
            </MobileMenuItem>
          ))}
          {!userInfo?.userId && (
            <>
              <MobileMenuItem onClick={handleLoginClick} sx={{
                whiteSpace: 'nowrap',
                width: '100%',
              }}>
                <ListItemIcon><LockIcon /></ListItemIcon>
                <ListItemText primary={<FormattedMessage id="menu.login" />} />
              </MobileMenuItem>
              <MobileMenuItem  sx={{
                whiteSpace: 'nowrap',
                width: '100%',
              }}  onClick={handleSignup}>
                <ListItemIcon><PersonAddIcon /></ListItemIcon>
                <ListItemText primary={<FormattedMessage id="menu.signup" />} />
              </MobileMenuItem>
            </>
          )}
          {userInfo?.userId && (
            <MobileMenuItem onClick={handleLogout}>
              <ListItemIcon><LogoutIcon /></ListItemIcon>
              <ListItemText primary={<FormattedMessage id="menu.logout" />} />
            </MobileMenuItem>
          )}
          {/* Close button to close the drawer */}
          <MobileMenuItem onClick={handleDrawerToggle}>
            <ListItemIcon><CloseIcon /></ListItemIcon>
            <ListItemText primary={<FormattedMessage id="menu.close" />} />
          </MobileMenuItem>
        </MobileMenuList>
      </Drawer>
      <Modal
        opened={showModal}
        onClose={() => setShowModal(false)}
        title="Sign up to our platform"
        size="lg"
        style={{ overflow: 'hidden' }} // Hide the modal's scrollbar
      >
        <div style={{
          padding: '16px',
          maxHeight: '400px', // Set a maximum height for the content
          overflowY: 'auto', // Make the content scrollable
        }}>
          <form onSubmit={handleSubmit}>
            <Text  style={{ padding: '4px' }}>Fill out the form below:</Text>
            <Input
              required
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              label="Name"
              placeholder="Enter your name"
              style={{ padding: '16px' }}
            />
            <Input
              required
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              type="email"
              label="Email"
              placeholder="Enter your email"
              style={{ padding: '16px' }}
            />
            <Input
              required
              value={formData.message}
              onChange={(e) =>
                setFormData({ ...formData, message: e.target.value })
              }
              multiline
              rows={4}
              label="Message"
              placeholder="Enter your message"
              style={{ padding: '16px' }}
            />
            <Button style={{ margin: '0px' }} type="submit">Submit</Button>
          </form>
        </div>
      </Modal>
      <ToastContainer/>
    </>
  );
};

export default MobileMenuPanel;