import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import brandLogo from "./../assets/brand.png";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../Redux/languageSlice";
import fongitLogo from "./../assets/fongit.png"; // Import the Fongit logo

import { FormattedMessage, useIntl } from 'react-intl';

const SecondFooter = () => {
  const getLanguageMessages = (language) => {
    switch (language) {
      case "en":
        return require("../lang/en.json");
      case "fr":
        return require("../lang/fr.json");
      case "es":
        return require("../lang/es.json");
      case "pt":
        return require("../lang/pt.json");
      // Add more languages here as needed
      default:
        return require("../lang/en.json");
    }
  };

//  const dispatch = useDispatch();
  const selectedLanguage = useSelector((state) => state.language);

  return (
    <footer className="bg-lightGrey" style={{ width: "100%", backgroundColor: "#f2f2f2", borderTop: ".1px solid #f2f2f2" }}>
      <Container>
        <div className="d-flex align-items-end justify-content-between" style={{ fontSize: "12px", marginTop: "10px" }}>
          <div className="d-flex align-items-center">
            <img src={brandLogo} alt="Verto Logo" style={{ width: "130px", height: "auto", marginRight: "5px" }} />
            <img src={fongitLogo} alt="Fongit Logo" style={{ width: "50px", height: "auto", marginLeft: "40px"}} />
            <span style={{ marginLeft: "10px", fontFamily: "Montserrat", fontStyle: "italic"}}>
              <FormattedMessage id="footerText.fongit" />
              </span>
          </div>          
          <div className="d-flex align-items-center">
            <div className="d-flex justify-content-end">
              <Link to="/ethics" className="text-muted me-3">
                {getLanguageMessages(selectedLanguage).footerLinks.ethics}
              </Link>
              <Link to="/terms" className="text-muted me-3">
                {getLanguageMessages(selectedLanguage).footerLinks.terms}
              </Link>
              <Link to="/privacy" className="text-muted me-3">
                {getLanguageMessages(selectedLanguage).footerLinks.privacy}
              </Link>
              <Link to="/press" className="text-muted me-3">
                {getLanguageMessages(selectedLanguage).footerLinks.press}
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default SecondFooter;