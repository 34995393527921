import { CURRENT_PURPOSE, WEBSITE_FARM_TRADE } from "./website-config";

const RealEstate = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "My workspace",
    url: "/my-list",
    enableAuthentication: true,
  },
  {
    name: "Marketplace",
    url: "/marketplace",
  },
  {
    name: "Transaction Explorer",
    url: "/transaction-explorer"
  },
  {
    name: "Verto GPT",
    url: "/gpt"
  },
];

export const MenuConfig = RealEstate;
  // CURRENT_PURPOSE === WEBSITE_FARM_TRADE
  //   ? [
  //     {
  //       name: "User",
  //       children: ["Add User", "Remove User"],
  //     },
  //     {
  //       name: "Product",
  //       children: [{ name: "Product List", link: "/product/list" }],
  //     },
  //     {
  //       name: "Home",
  //       children: ["Notifications"],
  //     },

  //     {
  //       name: "Commodities",
  //       children: ["Coffee", "Tea", "Turmeric", "Search"],
  //     },

  //     {
  //       name: "Pages",
  //       children: ["Offer", "New Arrivals", "History"],
  //     },

  //     {
  //       name: "Blog",
  //       children: ["Review"],
  //     },
  //   ]
  //   : RealEstate;
