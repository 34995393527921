import React, { useEffect, useState, useRef  } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Text, Container, Grid, Button, Image, Badge, Table, Input, Modal, Loader } from '@mantine/core';
import { getUserFullDetails } from '../Redux/user/slice';
import { addFieldAPI, getFieldAPI } from '../Redux/user/api';
import { IconEdit, IconSave, IconTrash } from '@tabler/icons-react';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastProperties } from '../utils/constants/toastProperties';
import { TailSpin } from 'react-loader-spinner';
import { IntlProvider, FormattedMessage, useIntl } from 'react-intl';

const RemoveFieldConfirmationModal = ({ fieldName, onConfirm, onCancel, opened }) => {
  return (
    <Modal
      title={`Remove Field "${fieldName}"`}
      size="sm"
      opened={opened}
      onClose={onCancel}
      shadow="xs"
    >
      <Text  style={{ margin:'10px' }}>
        <FormattedMessage id="myfarm.text1" />{fieldName}<FormattedMessage id="myfarm.text2b" />
      </Text>
      <Button variant="light" color="blue" onClick={onCancel}  style={{ margin:'10px' }}>
        <FormattedMessage id="button.cancel" />
      </Button>
      <Button variant="filled" color="red" onClick={onConfirm}  style={{ margin:'10px' }}>
        <FormattedMessage id="button.confirm" />
      </Button>
    </Modal>
  );
};

export default function MobileMyFarm() {
  const userinfo = JSON.parse(localStorage.getItem('userinfo'));
  const userFullDetails = useSelector((state) => state.user.userFullDetails);
  const dispatch = useDispatch();
  const [lastClickTime, setLastClickTime] = useState(0);

  const [farmFields, setFarmFields] = useState([]);
  const [isLoadingGPS, setIsLoadingGPS] = useState(false);
  const [manualEntry, setManualEntry] = useState(false); // State to track manual entry mode

  useEffect(() => {
    if (!userFullDetails.userId) {
      dispatch(getUserFullDetails({ email: userinfo.email }));
    } else {
      getFieldAPI(userFullDetails)
        .then((data) => {
          if (Array.isArray(data.result)) {
            setFieldData(data.result);
          } else {
            console.error('Invalid data format:', data);
            setFieldData([]);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [dispatch, userinfo.email, userFullDetails.userId]);

  const [showFieldSection, setShowFieldSection] = useState(false);
  const handleAddField = () => {
    // Only add a new field if the field section is not already shown
    if (!showFieldSection && farmFields.length === 0) {
      const field = { name: '', gpsPoints: [], manualLat: '', manualLng: '', region: "", product: "" };
      setFarmFields([...farmFields, field]);
    }
    // reset the field
    setFarmFields(prevFarmFields => prevFarmFields.map(field => ({ ...field, gpsPoints: [] })));
    // Toggle the showFieldSection state regardless of whether a field was added or not
    setShowFieldSection(!showFieldSection);
  };

  const handleCollectGPS = async (fieldIndex) => {
    const currentTime = Date.now();
    if (currentTime - lastClickTime < 2000) {
      // If the last click was less than 2 seconds ago, ignore this click
      return;
    }

    setLastClickTime(currentTime);

    setIsLoadingGPS(true);
    await navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const updatedFields = [...farmFields];
        updatedFields[fieldIndex].gpsPoints.push({ latitude, longitude, timestamp: new Date() });
        setFarmFields(updatedFields);
        setIsLoadingGPS(false);
      },
      (err) => {
        console.log(err);
        setIsLoadingGPS(false);
      },{timeout:10000, enableHighAccuracy: true, maximumAge: 0}
    );
  };

  const handleFieldNameChange = (e, fieldIndex) => {
    const updatedFields = [...farmFields];
    updatedFields[fieldIndex].name = e.target.value;
    setFarmFields(updatedFields);
  };

  const handleManualLatChange = (e, fieldIndex) => {
    const updatedFields = [...farmFields];
    updatedFields[fieldIndex].manualLat = e.target.value;
    setFarmFields(updatedFields);
  };

  const handleManualLngChange = (e, fieldIndex) => {
    const updatedFields = [...farmFields];
    updatedFields[fieldIndex].manualLng = e.target.value;
    setFarmFields(updatedFields);
  };

  const handleSaveManualGPS = (fieldIndex) => {
    const updatedFields = [...farmFields];
    updatedFields[fieldIndex].gpsPoints.push({
      latitude: parseFloat(updatedFields[fieldIndex].manualLat),
      longitude: parseFloat(updatedFields[fieldIndex].manualLng),
    });
    setFarmFields(updatedFields);
  };

  const isSaveButtonDisabled = farmFields.some((field) => field.gpsPoints.length < 3);

  const handleSave = (farmFieldsToSave) => {
    if (farmFieldsToSave.length === 0) {
      alert('No farm fields to save.');
      return;
    }

    const dataToSave = {
      userId: userinfo.userId,
      userAccountId: userinfo.accountId,
      farmFields: [...fieldData, ...farmFieldsToSave],
    };

    addFieldAPI(dataToSave)
      .then((response) => {
        if (response.status === "Success") {
          showToast('success', 'Field saved successfully');
          setFarmFields(prevFarmFields => prevFarmFields.map(field => ({ ...field, gpsPoints: [] })));
          // After saving, fetch and update the field data
          getFieldAPI(userFullDetails)
            .then((data) => {
              if (Array.isArray(data.result)) {
                setFieldData(data.result);
              } else {
                console.error('Invalid data format:', data);
                setFieldData([]);
              }
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
        } else {
         showToast('error', 'Failed to save data');
        }
      })
      .catch((error) => {
        console.error('Error saving data:', error);
        showToast('error', 'An error occurred while saving data');
      });
  };

  const [fieldData, setFieldData] = useState([]);

  // Initialize editedFields with the existing fieldData
  const [editedFields, setEditedFields] = useState([]);

  const [isEditing, setIsEditing] = useState(false); // State to track editing mode
  const [editingField, setEditingField] = useState(null);

  const handleEditField = (fieldName) => {
    setEditingField(fieldName);
    setIsEditing(true);

    // Clone the original farmFields for editing
    const clonedFields = JSON.parse(JSON.stringify(fieldData));
    setEditedFields(clonedFields);

  };

  const handleSaveField = (fieldName) => {
    setIsEditing(false);

    const editedField = editedFields[fieldName];
    const originalField = fieldData[fieldName];

    // Check if the name or gpsPoints are different
    const isNameModified = editedField.name !== originalField.name;
    const isGpsPointsModified = !arraysAreEqual(editedField.gpsPoints, originalField.gpsPoints);

    // If either the name or gpsPoints is modified, call the handleSave method
    if (isNameModified || isGpsPointsModified) {
      const dataToSave = {
        userId: userinfo.userId,
        userAccountId: userinfo.accountId,
        farmFields: editedFields,
      };
      addFieldAPI(dataToSave)
        .then((response) => {
          if (response.status === "Success") {
            showToast('success', 'Field updated successfully');
            // After saving, fetch and update the field data
            getFieldAPI(userFullDetails)
              .then((data) => {
                if (Array.isArray(data.result)) {
                  setFieldData(data.result);
                } else {
                  console.error('Invalid data format:', data);
                  setFieldData([]);
                }
              })
              .catch((error) => {
                console.error('Error fetching data:', error);
              });
          } else {
            showToast('error', 'Failed to update field');
          }
        })
        .catch((error) => {
          console.error('Error updating field:', error);
          showToast('error', 'An error occurred while saving data');
        });
    }

    // Update the original farmFields with edited data
    setFieldData(editedFields);
  };

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [fieldToRemove, setFieldToRemove] = useState(null);

  const handleRemoveField = (index) => {
    const fieldToRemove = fieldData[index].name;
    // Open the confirmation modal
    setIsConfirmationModalOpen(true);
    setFieldToRemove(fieldToRemove);
  };

  const handleConfirmRemove = () => {
    // Close the confirmation modal when confirmed
    setIsConfirmationModalOpen(false);

    // Your removal logic here
    const updatedEditedFields = fieldData.filter((field) => field.name !== fieldToRemove);
    setEditedFields(updatedEditedFields);

    // Upload the updated data without the removed field
    const dataToSave = {
      userId: userinfo.userId,
      userAccountId: userinfo.accountId,
      farmFields: updatedEditedFields,
    };

    addFieldAPI(dataToSave)
      .then((response) => {
        if (response.status === "Success") {
          showToast('success', 'Field removed successfully');
          // After removing the field, fetch and update the field data
          getFieldAPI(userFullDetails)
            .then((data) => {
              if (Array.isArray(data.result)) {
                setFieldData(data.result);
              } else {
                console.error('Invalid data format:', data);
                setFieldData([]);
              }
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
        } else {
          showToast('error', 'Failed to remove field');
        }
      })
      .catch((error) => {
        console.error('Error removing field:', error);
        showToast('error','An error occurred while removing the field');
      });
  };

  const handleCancelRemove = () => {
    // Your cancel logic here
    // Close the confirmation modal when canceled
    setIsConfirmationModalOpen(false);
  };

  // Helper function to check if two arrays are equal
  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    for (let i = 0; i < arr1.length; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
        return false;
      }
    }

    return true;
  }

  const handleEditValues = (e, fieldName, rowIndex, columnName) => {
    if (isEditing) {
      const updatedFields = [...editedFields];
      const fieldIndex = parseInt(fieldName, 10); // base 10
      updatedFields[fieldIndex]["gpsPoints"][rowIndex][columnName] = e.target.value;
      setEditedFields(updatedFields);
    }
  };

  const handleEditName = (e, fieldName, key) => {
    if (isEditing) {
      const updatedFields = [...editedFields];
      const fieldIndex = parseInt(fieldName, 10); // base 10
      updatedFields[fieldIndex][key] = e.target.value;
      setEditedFields(updatedFields);
    }
  };

  const handleFieldChange = (e, fieldIndex, key) => {
    const updatedFields = [...farmFields];
    updatedFields[fieldIndex][key] = e.target.value;
    setFarmFields(updatedFields);
  }; 

  const showToast = (type, message) => {

    // Use the toast function to display the appropriate toast message
    if (type === 'success') {
      toast.success(message, {
        ...ToastProperties
      });
    } else if (type === 'error') {
      toast.error(message, {
        ...ToastProperties
      });
    }
  };

  const intl = useIntl();

  const mapurl = `https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/userSpace/${userinfo.accountId}/farm_fields_map.html`;

  return (
    <Container size="md" style={{ display: 'center', adding: '10px' }}>
      <Paper padding="md" shadow="xs" style={{ display: 'flex', flexDirection: 'column', height: '300px' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <iframe 
          src={mapurl}
          style={{ width: '95%', height: '95%', border: 'none' }} 
          title="Embedded HTML"
        ></iframe>
      </div>
      </Paper>
      <Paper padding="md" shadow="xs" style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
        <div style={{ padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Image
            src="https://vertoledgerstorage.blob.core.windows.net/sqldbledgerdigests/images/farm.png"
            alt="User Profile Image"
            style={{ width: '200px', height: 'auto' }}
          />
        </div>
        {Object.keys(fieldData).map((fieldName) => (
          <div key={fieldName} style={{ marginBottom: '20px' }}>
            <div style={{ marginBottom: '10px', padding: '10px' }}>
              <h6 style={{
                minWidth: '200px',
                maxWidth: '200px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
                    {isEditing && editingField === fieldName ? (
                      <>
                        <div style={{ display: 'flex', flexDirection: 'column'  }}>
                          <label>{intl.formatMessage({ id:"myfarm.field.name" })}</label>
                          <Input
                            value={editedFields[fieldName]?.name || ''}
                            onChange={(e) => handleEditName(e, fieldName, 'name')}
                          />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column'  }}>
                          <label>{intl.formatMessage({ id:"myfarm.field.product" })}</label>
                          <Input
                            value={editedFields[fieldName]?.product || ''}
                            onChange={(e) => handleEditName(e, fieldName, 'product')}
                          />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column'  }}>
                          <label>{intl.formatMessage({ id:"myfarm.field.region" })}</label>
                          <Input
                            value={editedFields[fieldName]?.region || ''}
                            onChange={(e) => handleEditName(e, fieldName, 'region')}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <label style={{ marginRight: '10px' }}>
                            {intl.formatMessage({ id: "myfarm.field.name" })}:
                          </label>
                          <div style={{ fontWeight: 'normal' }}>{fieldData[fieldName]?.name || ''}</div>
                        </div>
                        <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <label style={{ marginRight: '10px' }}>
                            {intl.formatMessage({ id: "myfarm.field.product" })}:
                          </label>
                          <div style={{ fontWeight: 'normal' }}>{fieldData[fieldName]?.product || ''}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <label style={{ marginRight: '10px' }}>
                            {intl.formatMessage({ id: "myfarm.field.region" })}:
                          </label>
                          <div style={{ fontWeight: 'normal' }}>{fieldData[fieldName]?.region || ''}</div>
                        </div>
                      </>
                    )}
              </h6>
              <div>
                {isEditing && editingField === fieldName ? (
                  <Button color="teal" variant="light" onClick={() => handleSaveField(fieldName)}>
                    <FormattedMessage id="button.save" />
                  </Button>
                ) : (
                  <div>
                    <IconEdit
                      size={20}
                      style={{ cursor: 'pointer', marginLeft: '10px' }}
                      onClick={() => handleEditField(fieldName)}
                    />
                    <IconTrash
                      size={20}
                      style={{ cursor: 'pointer', marginLeft: '10px' }}
                      onClick={() => handleRemoveField(fieldName)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div style={{ overflowX: 'auto', padding: '10px' }}>
              <Table sx={{ color: '#002040', width: '100%' }} striped highlightOnHover withBorder withColumnBorders>
                <thead>
                  <tr>
                    <th><FormattedMessage id="myfarm.field.latitude" /></th>
                    <th><FormattedMessage id="myfarm.field.longitude" /></th>
                  </tr>
                </thead>
                <tbody>
                  {fieldData[fieldName]?.gpsPoints?.map((point, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>
                        {isEditing && editingField === fieldName ? (
                          <Input
                            value={editedFields[fieldName]?.gpsPoints[rowIndex]?.latitude || ''}
                            onChange={(e) => handleEditValues(e, fieldName, rowIndex, 'latitude')}
                          />
                        ) : (
                          point?.latitude || '' // Access latitude property safely
                        )}
                      </td>
                      <td>
                        {isEditing && editingField === fieldName ? (
                          <Input
                            value={editedFields[fieldName]?.gpsPoints[rowIndex]?.longitude || ''}
                            onChange={(e) => handleEditValues(e, fieldName, rowIndex, 'longitude')}
                          />
                        ) : (
                          point?.longitude || '' // Access longitude property safely
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        ))}
      </Paper>
      <Paper padding="md" shadow="xs" style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
        <div style={{ padding: '20px' }}>
          <Button color="teal" onClick={handleAddField}><FormattedMessage id="button.add.farm" /></Button>
          {showFieldSection && (
            <div>
                {farmFields.map((field, index) => (
                  <div key={index} style={{ marginTop: '10px', padding: '5px' }}>
                    <input
                      type="text"
                      placeholder={intl.formatMessage({ id:"myfarm.field.name" })}
                      value={field.name}
                      onChange={(e) => handleFieldChange(e, index, 'name')}
                      style={{ margin: '10px',  padding: '5px' }}
                    />
                    <input
                      type="text"
                      placeholder={intl.formatMessage({ id:"myfarm.field.product" })}
                      value={field.product}
                      onChange={(e) => handleFieldChange(e, index, 'product')}
                      style={{ margin: '10px',  padding: '5px' }}
                    />
                    <input
                      type="text"
                      placeholder={intl.formatMessage({ id:"myfarm.field.region" })}
                      value={field.region}
                      onChange={(e) => handleFieldChange(e, index, 'region')}
                      style={{ margin: '10px',  padding: '5px' }}
                    />
                    {/* Create the choice indicator section */}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        background: 'teal',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '5px',
                        marginBottom: '10px',
                        width: 'fit-content', // Adjusted property
                      }}
                    >
                      <Button
                        color="teal"
                        variant={manualEntry ? 'light' : 'outline'}
                        onClick={() => setManualEntry(true)}
                      >
                        <FormattedMessage id="myfarm.field.manualentry" />
                      </Button>
                      <div
                        key={index}
                        style={{
                          marginLeft: '5px', // Adjust the margin as needed
                          marginRight: '5px', // Adjust the margin as needed
                        }}
                      >
                        Or
                      </div>
                      <Button
                        color="teal"
                        variant={!manualEntry ? 'light' : 'outline'}
                        onClick={() => handleCollectGPS(index)}
                      >
                        <FormattedMessage id="myfarm.field.collectGPS" />
                        {isLoadingGPS && (
                          <Loader
                            size="sm"
                            style={{
                              position: 'absolute',
                              top: '50%',
                              right: '10px',
                              transform: 'translateY(-50%)',
                            }}
                          />
                        )}
                      </Button>
                    </div>
                    {/* Conditionally render GPS or manual entry fields */}
                    {manualEntry ? (
                      <div>
                        <Input
                          label={intl.formatMessage({ id:"myfarm.field.manlat.label" })}
                          placeholder={intl.formatMessage({ id:"myfarm.field.manlat.lorem" })}
                          value={field.manualLat}
                          onChange={(e) => handleManualLatChange(e, index)}
                          style={{ marginBottom: '10px', padding: '5px' }} // Add padding to input fields
                        />
                        <Input
                          label={intl.formatMessage({ id:"myfarm.field.manlong.label" })}
                          placeholder={intl.formatMessage({ id:"myfarm.field.manlong.lorem" })}
                          value={field.manualLng}
                          onChange={(e) => handleManualLngChange(e, index)}
                          style={{ marginBottom: '10px', padding: '5px' }} // Add padding to input fields
                        />
                        {/* Render the "Save GPS" button */}
                        <Button
                          color="teal"
                          onClick={() => handleSaveManualGPS(index)}
                          // style={{ margin: '5px' }}
                          disabled={isLoadingGPS}
                        >
                          {isLoadingGPS ? 'Collecting...' : 'Save manual GPS'}
                        </Button>
                      </div>
                    ) : null}
                    {field.gpsPoints.map((point, pointIndex) => (
                      <div key={pointIndex} style={{ padding: '5px' }}>
                        <FormattedMessage id="myfarm.field.gpspt" />{pointIndex + 1}<FormattedMessage id="myfarm.field.gpspt.lat" />{point.latitude}<FormattedMessage id="myfarm.field.gpspt.long" />{point.longitude}
                      </div>
                    ))}
                  </div>
                ))}
              <div>
              {farmFields.length > 0 && (
                <Button color="teal" onClick={() => handleSave(farmFields)} disabled={isSaveButtonDisabled}>
                  <FormattedMessage id="button.save" />
                </Button>
              )}
              </div>
            </div>
          )}
        </div>
      </Paper>
      <RemoveFieldConfirmationModal
        fieldName
        onConfirm={handleConfirmRemove}
        onCancel={handleCancelRemove}
        opened={isConfirmationModalOpen} // Pass the opened prop
      />
    </Container>
  );
}
