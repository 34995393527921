import { Grid, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import FilterComponent from "../components/FilterComponent";
import ItemCard from "../components/ItemCard";
import MenuPanel from "../components/MenuPanel";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { getProducts } from "../Redux/CounterSlice";
import {
  fetchUserAccount,
  getPropertiesReducer,
} from "../Redux/properties/PropertiesSlice";
import {
  CURRENT_PURPOSE,
  WEBSITE_CONSTANTS,
  WEBSITE_FARM_TRADE,
  WEBSITE_REAL_ESTATE,
} from "../configs/website-config";
import RealEstateMediaCard from "../components/RealEstateItem";
import { HashConnect } from "hashconnect";
import SalePanel from "../components/SalePanel";
import { LoginModal } from "../components/LoginModal";
import MobileFooter from "../components/MobileFooter";
import MobileSecondFooter from "../components/MobileSecondFooter";
import desk from "../assets/images/desk.png";
import graph1 from "../assets/images/graph-1.png";
import hero1 from "../assets/images/hero1.jpg";
import hero3 from "../assets/images/hero3.jpeg";
import pate from "../assets/images/pate.jpg";
import livre_blanc from "../assets/images/livre_blanc.png";
import desktop from "../assets/images/desktop-1.webp";
import { Popover, Text, Button } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import { IconPhone } from '@tabler/icons-react';
import { FormattedMessage } from "react-intl";
import { setLanguage } from "../Redux/languageSlice";
import MobileMenuPanel from "../components/MobileMenuPanel";
import { BrowserRouter } from "react-router-dom"; 

const backgroundImage =
  CURRENT_PURPOSE === WEBSITE_REAL_ESTATE
    ? "../assets/bg.jpeg"
    : "../assets/summer.png";

const style = {
  papaerStyle: {
    height: "100vh",
    width: "100%",
    backgroundImage: "url('" + backgroundImage + "')",
    overflow: "auto"
  },
  gridStyle: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    // backgroundColor: "rgba(00,00,99,0.75)",
    // backgroundColor: "rgb(122 139 164 / 75%)",
    //  backgroundColor: "#371D10",
  },
  addressBar: {
    height: "5vh",
    width: "90vw",
    opacity: 1,
    marginTop: "0px",
    marginRight: "100px",
    marginLeft: "100px",
  },
  menuBar: {
    height: "50px",
    width: "100%",
    opacity: 1,
    // marginTop: "10px",
    // marginRight: "100px",
    // marginLeft: "100px",
  },
  titleBar: {
    height: "25vh",
    opacity: 1,
  },
  dummBar: {
    height: "100px",
    color: "#ffffff",
    textAlign: "center",
    fontSize: "35px",
    fontWeight: "bolder",
    opacity: 1,
    // paddingLeft: "10%",
    // paddingRight: "10%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dummBar1: {
    height: "10px",
    color: "#ffffff",
    textAlign: "center",
    fontSize: "35px",
    fontWeight: "bolder",
    opacity: 1,
  },
  lineDiv: {
    height: "100px",
    color: "#ffffff",
    textAlign: "center",
    fontSize: "35px",
    fontWeight: "bolder",
    opacity: 1,
    marginTop: "0%",
  },
  Filter: {
    // height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80px"
  },
  items: {
    height: "auto",
  },
  whole: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex"
    // position: "fixed",
  },
};

const MobileHomePage = () => {
  let { level } = useParams();
  const [filter, setFilter] = useState({ category: '', product: '' })
  const products = useSelector((state) => state.properties.data);

  const selectedLanguage = useSelector((state) => state.language);

  const productsFilter = useMemo(() => {
    return products.filter(e => {
      console.log({ upc: e.UPC })
      return e.UPC.toLowerCase().includes(filter.category.toLowerCase()) && e.productName.toLowerCase().includes(filter.product.toLowerCase())
    })
  }, [products, filter])

  const realEstateData = useSelector((state) => state.properties);

  const dispatch = useDispatch();
  const [salePanelModal, setSalePanelModal] = useState(false);
  const [saleSelected, setSaleSelected] = useState();

  useEffect(() => {
    if (saleSelected) console.log("saleSelected", saleSelected);
    setSalePanelModal(true);
  }, [saleSelected]);
  const fetchWallet = () => {
    if (localStorage.getItem("userLogged")) {
      dispatch(fetchUserAccount(localStorage.getItem("userLogged")));
    } else {
      dispatch(fetchUserAccount("testbuyer1"));
    }
  };

  const Block1 = () => {
    const blockStyle = {
      height: "auto",
      backgroundColor: "#F6F7F9",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Montserrat",
      textAlign: "center",
    };

    const containerStyle = {
      width: "90%",
    };

    const titleStyle = {
      fontSize: "32px",
      fontWeight: "bold",
      margin: "30px",
    };

    const subtitleStyle = {
      fontSize: "22px",
    };

    const imageStyle = {
      width: "90%",
      margin: "30px",
    };

    return (
      <div className="block" style={blockStyle}>
        <div style={containerStyle}>
          <h2 style={titleStyle}>
            <FormattedMessage id="app.homepage.title" />
          </h2>
          <h3 style={subtitleStyle}><FormattedMessage id="app.homepage.subtitle" /></h3>
        </div>
        <div>
          <img src={desktop} alt="Desktop" style={imageStyle} />
        </div>
      </div>
    );
  };

  const Block2 = () => {
    const blockStyle = {
      height: "auto",
      backgroundColor: "#F5F6EE",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Montserrat",
      textAlign: "center",
    };

    const containerStyle = {
      width: "95%",
      display: "flex",
      alignItems: "center", // Updated to align items to the top
      justifyContent: "space-between",
      marginTop: "10px",
    };

    const bulletStyle = {
      width: "95%", // Adjust the width as needed
      fontSize: "18px",
      margin: "5px",
      textAlign: "left",
      listStyleType: "none",
    };

    const imageStyle = {
      width: "90%", // Adjust the width as needed
      height: "auto",
      alignSelf: "center", // Added to vertically center the image
    };

    const listItemStyle = {
      display: "flex",
      alignItems: "center",
      marginBottom: "20px",
    };

    const tickStyle = {
      display: "inline-block",
      width: "50px",
      height: "50px",
      marginRight: "10px",
    };

    const bulletPoints = [
      {
        title:  <FormattedMessage id="app.homepage.block2.bullet1.title" />,
        description: <FormattedMessage id="app.homepage.block2.bullet1.description" />,
      },
      {
        title: <FormattedMessage id="app.homepage.block2.bullet2.title" />,
        description: <FormattedMessage id="app.homepage.block2.bullet2.description" />,
      },
      {
        title: <FormattedMessage id="app.homepage.block2.bullet3.title" />,
        description: <FormattedMessage id="app.homepage.block2.bullet3.description" />,
      },
    ];

    const titleStyle = {
      fontSize: "28px",
      margin: "40px",
      fontWeight: "bold",
      alignItems: "center",
      justifyContent: "center",
    };

    return (
      <div className="block" style={blockStyle}>
        <h2 style={titleStyle}><FormattedMessage id="app.homepage.block2.title" /></h2>
        <div style={containerStyle}>
          <div className="bullets" style={bulletStyle}>
            <ul>
              {bulletPoints.map((bulletPoint, index) => (
                <li key={index} style={listItemStyle}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    style={tickStyle}
                  >
                    <path
                      fill="green"
                      d="M9.05 16.65L4.7 12.3l1.41-1.41L9.05 13.83l8.48-8.48L19.94 6l-10 10z"
                    />
                  </svg>
                  <div>
                    <p style={{ margin: 0, display: "block" }}>
                      <strong>{bulletPoint.title}</strong>
                    </p>
                    <p style={{ margin: 0, display: "block" }}>{bulletPoint.description}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {/* <div className="image">
            <img src={graph1} alt="Image" style={imageStyle} />
          </div> */}
        </div>
      </div>
    );
  };

  const Block3 = () => {
    const blockStyle = {
      backgroundColor: "#D9EADA",
      display: "flex",
      flexDirection: "column", // Arrange children in a column
      alignItems: "center", // Center items horizontally
      justifyContent: "space-between", // Distribute space evenly between items
      fontFamily: "Montserrat",
      textAlign: "left",
      borderRadius: "10px",
      padding: "10px",
      marginTop: "0px",
      flexGrow: 1, // Expand to take up available vertical space
    };

    const mainTitleStyle = {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "10px",
      marginTop: "20px", // Adjust the margin from the top block border
      textAlign: "center", // Center the main title horizontally
    };

    const titleStyle = {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "10px",
      marginTop: "20px", // Adjust the margin from the top block border
      textAlign: "center", // Center the main title horizontally
    };

    const subtitleStyle = {
      fontSize: "18px",
      marginBottom: "50px",
      marginTop: "20px", // Adjust the margin from the subtitle
      textAlign: "center", // Center the subtitle horizontally
    };

    const descriptionStyle = {
      fontSize: "18px",
      marginBottom: "0px",
      padding: "10px",
    };

    const subDescriptionStyle = {
      fontSize: "16px",
      color: "gray",
      marginTop: "0px", // Adjust the margin from the sub blocks
    };

    const blockContainerStyle = {
      display: "flex-grow",
      flexDirection: "column", // Change to row for mobile view
      // justifyContent: "space-between",
      width: "100%",
      height: "100%",
      marginTop: "20px", // Adjust the margin between the 3 blocks
      // flexWrap: "wrap", 
    };

    const blockItemStyle = {
      margin: "10px", // Adjust the margin between the sub blocks
      textAlign: "center",
      // flexBasis: "calc(33.33% - 20px)",
      // display: "flex-grow", // Make block items flex containers
      // flexDirection: "column", // Arrange content in a column
      // alignItems: "center", // Center content horizontally
    };

    const imageStyle = {
      width: "100%", // Set the desired width
      // height: "200px", // Set the desired height
      borderRadius: "10px",
    };

    const blockContent = [
      {
        image: pate,
        title: <FormattedMessage id="app.homepage.block3.step1.title" />,
        description: <FormattedMessage id="app.homepage.block3.step1.description" />,
        subDescription: <FormattedMessage id="app.homepage.block3.step1.subDescription" />,
      },
      {
        image: hero1,
        title: <FormattedMessage id="app.homepage.block3.step2.title" />,
        description: <FormattedMessage id="app.homepage.block3.step2.description" />,
        subDescription: <FormattedMessage id="app.homepage.block3.step2.subDescription" />,
      },
      {
        image: desk,
        title: <FormattedMessage id="app.homepage.block3.step3.title" />,
        description: <FormattedMessage id="app.homepage.block3.step3.description" />,
        subDescription: <FormattedMessage id="app.homepage.block3.step3.subDescription" />,
      },
    ];

    return (
      <div className="block" style={blockStyle}>
        <h2 style={mainTitleStyle}>
          <FormattedMessage id="app.homepage.block3.title" />
        </h2>
        <h3 style={subtitleStyle}>
          <FormattedMessage id="app.homepage.block3.subtitle" />
        </h3>
        <div className="blocks" style={blockContainerStyle}>
          {blockContent.map((block, index) => (
            <div className="block" style={blockItemStyle} key={index}>
              <img src={block.image} alt={`Image ${index + 1}`} style={imageStyle} />
              <p style={titleStyle}><strong>{block.title}</strong></p>
              <p style={descriptionStyle}>{block.description}</p>
              <p style={subDescriptionStyle}>{block.subDescription}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const Block4 = () => {
    const blockStyle = {
      height: "auto",
      flexDirection: "column",
      backgroundColor: "#272635",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontFamily: "Montserrat",
      padding: "20px",
    };

    const containerStyle = {
      display: "flex",
      width: "95%",
      marginTop: "20px", // Adjust the margin from the top block border
      marginBottom: "50px", // Adjust the margin from the bottom block border
    };

    const textStyle = {
      width: "95%",
      color: "#FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    };

    const imageStyle = {
      width: "95%",
      height: "auto", // Set the desired height
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "20px",
      marginTop: "20px",
    };

    const mainTitleStyle = {
      fontSize: "32px",
      fontWeight: "bold",
      marginBottom: "20px",
      marginTop: "20px",
    };

    const subtitleStyle = {
      fontSize: "18px",
      marginBottom: "20px",
      marginTop: "20px",
      fontWeight: "bold",
    };

    const answerStyle = {
      fontSize: "18px",
      marginBottom: "20px",
      marginTop: "10px",
    };

    const buttonStyle = {
      marginTop: "10px",
      width: "fit-content",
    };

    return (
      <div className="block" style={blockStyle}>
        <div className="container" style={containerStyle}>
          <div className="text" style={textStyle}>
            <h2 style={mainTitleStyle}>
              <FormattedMessage id="app.homepage.block4.title" />
            </h2>
            <div className="image" style={imageStyle}>
              <img
                src={livre_blanc}
                alt="Image"
                style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: "10px" }}
              />
            </div>
            <h3 style={subtitleStyle}>
              <FormattedMessage id="app.homepage.block4.subtitle" />
            </h3>
            <p style={answerStyle}>
              <FormattedMessage id="app.homepage.block4.description" />
            </p>
            <Button
              component="a"
              href="/assets/Verto-SmartContractForTraceabilityWhitePaper.pdf"
              variant="outline"
              leftIcon={<IconDownload size="0.9rem" />}
              download
              style={buttonStyle}
            >
              <FormattedMessage id="app.homepage.block4.buttonText" />
            </Button>
          </div>
        </div>
      </div>
    );
  };


  const Block5 = () => {
    const handleClick = () => {
      const phoneNumber = "+41 76 609 25 37";
      alert(`Call us at: ${phoneNumber}`);
    };

    const blockStyle = {
      height: "auto",
      backgroundColor: "#B1E5F2",
      display: "flex",
      alignItems: "left",
      justifyContent: "left",
      fontFamily: "Montserrat",
      padding: "20px",
    };

    const containerStyle = {
      backgroundColor: "#F6F7F9",
      display: "flex",
      width: "95%",
      marginBottom: "100px",
      marginTop: "100px",
    };

    const textStyle = {
      // width: "66%",
      color: "#000000",
      flexDirection: "column",
      justifyContent: "left",
      margin: "10px",
      padding: "20px",
    };

    const logoStyle = {
      width: "70px",
      height: "70px",
      marginBottom: "10px",
    };

    const mainTitleStyle = {
      display: "flex",
      alignItems: "center",
      fontSize: "28px",
      fontWeight: "bold",
      marginBottom: "10px",
      marginTop: "10px",
    };

    const subtitleStyle = {
      fontSize: "16px",
      marginBottom: "30px",
      marginTop: "10px",
      fontWeight: "bold",
    };

    const buttonStyle = {
      marginTop: "10px",
      width: "fit-content",
    };

    const phoneNumber = "+41 76 609 25 37";

    return (
      <div className="block" style={blockStyle}>
        <div className="container" style={containerStyle}>
          <div className="text" style={textStyle}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "0px" }}>
              <IconPhone size="20" style={{ marginRight: "10px" }} />
              <h2 style={mainTitleStyle}>
                <FormattedMessage id="app.homepage.block5.title" />
              </h2>
            </div>
            <h3 style={subtitleStyle}>
              <FormattedMessage id="app.homepage.block5.subtitle" />
            </h3>
            <Popover
              // width="target"
              position="bottom"
              withArrow
              shadow="md"
              closeOnClickOutside
              closeOnEscape
            >
              <Popover.Target>
                <Button onClick={handleClick} width={100}>
                  <FormattedMessage id="app.homepage.block5.buttonText" />
                </Button>
              </Popover.Target>
              <Popover.Dropdown style={{ padding: "10px", minWidth: "auto", height: "auto" }}>
                <Text size="sm">{phoneNumber}</Text>
              </Popover.Dropdown>
            </Popover>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {saleSelected && (
        <SalePanel
          modal={salePanelModal}
          toggle={() => {
            setSalePanelModal(false);
            setSaleSelected(undefined);
          }}
          title={saleSelected?.title}
          value={saleSelected}
        >
          {" "}
        </SalePanel>
      )}
      <Grid container sx={style.gridStyle}>
        <MobileMenuPanel level={level} selectedLanguage={selectedLanguage} />
        <Block1 />
        <Block2 />
        <Block3 />
        <Block4 />
        <Block5 />
        <MobileSecondFooter />
        <MobileFooter />
      </Grid>
      <LoginModal />
    </>
  );
};

export default MobileHomePage;