import React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import WebRouter from "./routers/WebRouter";
import MobileWebRouter from "./routers/MobileWebRouter";
import store from "./Redux/store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { IdleTimeout } from './utils/idleTimeout';

function App() {

  return (
    <>
      {console.log("APP PROVIDER SRC store: store.getState()", { store: store.getState() })}

      <MobileView>
        {/* Mobile-specific layout */}
        {/* <MobileViewComponent /> */}
        <MobileWebRouter />
      </MobileView>
      <BrowserView>
        {/* Web view layout */}
        <ToastContainer rtl />
        <WebRouter />
      </BrowserView>
      <IdleTimeout /> {/* Include the IdleTimeout component here */}
    </>
  );
}

export default App;